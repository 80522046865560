<template>
  <div>
    <Modal @close="$emit('close')">
      <form @submit.prevent="guardar_acreditador">
        <div class="title">Acreditador</div>
        <div class="body">
          <form action="">
            <div class="row form-group">
              <label for="account_id" class="col-form-label col-sm-3">Usuario</label>
              <div class="col-sm-9">
                <select v-model="tmp_acreditador.account_id" name="account_id" id="account_id" class="form-control" :disabled="acreditador">
                  <option value="0">Selecciona un usuario</option>
                  <option v-for="usuario in usuarios_sin_usar" :value="usuario.id">{{ usuario.nombre }}</option>
                </select>
              </div>
            </div>
            <div class="row form-group">
              <label for="tipo" class="col-form-label col-sm-3">Tipo</label>
              <div class="col-sm-9">
                <select v-model="tmp_acreditador.tipo" name="tipo" id="tipo" class="form-control">
                  <option value="preponderante">Preponderante (decisión final)</option>
                  <option value="votador">Votante</option>
                </select>
              </div>
            </div>
            <div class="row form-group">
              <label for="obligatorio" class="col-form-label col-sm-3">Obligatorio</label>
              <div class="col-sm-9">
                <select v-model="tmp_acreditador.obligatorio" name="obligatorio" id="obligatorio" class="form-control">
                  <option value="1">Sí</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="row form-group">
              <label for="estatus" class="col-form-label col-sm-3">Estatus</label>
              <div class="col-sm-9">
                <select v-model="tmp_acreditador.estatus" name="estatus" id="estatus" class="form-control">
                  <option value="activo">Activo</option>
                  <option value="inactivo">Inactivo</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Guardar</button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/analyticpoint/api/comite/acreditadores';

export default {
  components: {
    Modal
  },
  props:{
    acreditador: {
      type: Object
    },
    acreditadores: {
      type: Array
    }
  },
  data() {
    return {
      usuarios: [],
      tmp_acreditador: {
        account_id: 0,
        tipo: 'preponderante',
        obligatorio: 1,
        estatus: 'activo'
      }
    }
  },
  mounted() {
    this.obtener_usuarios();

    if (this.acreditador)
      this.tmp_acreditador = {
        id: this.acreditador.id,
        account_id: this.acreditador.account_id,
        tipo: this.acreditador.tipo,
        obligatorio: this.acreditador.obligatorio ? 1 : 0,
        estatus: this.acreditador.estatus
      }
  },
  methods: {
    async obtener_usuarios() {
      try {
        this.usuarios = (await api.obtener_usuarios_account()).data;
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async guardar_acreditador() {
      try {
        let res = '';

        if (!this.tmp_acreditador.id)
          res = (await api.crear_acreditador(this.tmp_acreditador)).data;
        else
          res = (await api.editar_acreditador(this.tmp_acreditador.id, this.tmp_acreditador)).data;

        this.$log.info('res', res);
        this.$emit('update');
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  },
  computed: {
    usuarios_sin_usar() {
      let usuarios = [];

      if (this.acreditador && this.acreditador.id)
        return this.usuarios;

      this.usuarios.forEach(usuario => {
        let encontrado = false;

        for(let i=0; i<this.acreditadores.length; i++) {
          if (usuario.id == this.acreditadores[i].account_id) {
            encontrado = true;
            i = this.acreditadores.length;
          }
        }

        if (!encontrado)
          usuarios.push(usuario);
      });

      return usuarios;
    }
    ,c() {
      return !this.acreditador && this.acreditador.id;
    }
  }
}
</script>